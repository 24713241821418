import { useCallback, useEffect, useRef, useId, PointerEventHandler, RefObject } from 'react';
import { useStore } from '../providers/store-provider';
import {
  setCardDeactivationTimeout,
  clearCardDeactivationTimeout,
} from '../utils/card-deactivation-timeout';
import { ticker } from '../utils/ticker';
import { navSystem } from '../utils/nav-system';

function pointerActivationTracker(
  cardElement: HTMLElement,
  pointerStartPosition: { x: number; y: number },
  activateCard: () => void,
  options: {
    frameThreshold?: number;
    timeThreshold?: number;
    movementThreshold?: number;
  } = {},
) {
  const tickerListenerId = Symbol();
  const pointerPosition = { ...pointerStartPosition };

  // We store the frame data in a list so we can make an observation of the
  // pointer movement over a period of time.
  const frameList: { x: number; y: number; deltaTime: number }[] = [];

  // This value sets the minimum delta time between frames. The main idea for
  // this is to avoid excess work on screens with a high refresh rate.
  const minDeltaTime = 16;

  const { frameThreshold = 3, timeThreshold = 75, movementThreshold = 5 } = options;

  let previousTimestamp = 0;
  let deltaTime = 0;
  let checkForActivation = false;

  const tryActivateCard = () => {
    // If we don't have enough frames to make an observation, let's return
    // early.
    if (frameList.length < frameThreshold) return;

    // Find the observation start frame.
    let startFrame: (typeof frameList)[number] | null = null;
    let startFrameIndex = 0;
    let observationTime = 0;
    for (let i = 0; i < frameList.length; i++) {
      const frame = frameList[i]!;
      observationTime += frame.deltaTime;
      if (observationTime > timeThreshold && i + 1 >= frameThreshold) {
        startFrame = frame;
        startFrameIndex = i;
        break;
      }
    }

    // If no start frame was found let's return early.
    if (!startFrame) return;

    // Get rid of frames before the start frame.
    frameList.length = startFrameIndex + 1;

    // Track movement diff, if the movement diff between start frame and any
    // consecutive frame is greater than the movement threshold we don't
    // activate the card. Otherwise, activate the card.
    let i = frameList.length;
    while (i--) {
      const frame = frameList[i]!;
      const diffX = Math.abs(frame.x - startFrame.x);
      const diffY = Math.abs(frame.y - startFrame.y);
      if (diffX > movementThreshold || diffY > movementThreshold) {
        return;
      }
    }

    // If the movement was within the threshold, stop the ticker and activate
    // the card.
    destroy();
    activateCard();
  };

  // Creates and stores frame data.
  const createFrameData = (deltaTime: number) => {
    const rect = cardElement.getBoundingClientRect();
    frameList.unshift({
      x: pointerPosition.x - rect.left,
      y: pointerPosition.y - rect.top,
      deltaTime,
    });
  };

  // Keep track of pointer position.
  const onPointerMove = (event: PointerEvent) => {
    pointerPosition.x = event.clientX;
    pointerPosition.y = event.clientY;
  };

  // Tick handler for the DOM read phase. Used to track pointer movement.
  const readTick = (timestamp: DOMHighResTimeStamp) => {
    if (previousTimestamp !== 0) {
      deltaTime += timestamp - previousTimestamp;
      if (deltaTime >= minDeltaTime) {
        createFrameData(deltaTime);
        deltaTime = 0;
        checkForActivation = true;
      }
    }
    previousTimestamp = timestamp;
  };

  // Tick handler for the DOM write phase. Used to check if the card should be
  // activated.
  const writeTick = () => {
    if (checkForActivation) {
      checkForActivation = false;
      tryActivateCard();
    }
  };

  const destroy = () => {
    ticker.off('read', tickerListenerId);
    ticker.off('write', tickerListenerId);
    cardElement.removeEventListener('pointermove', onPointerMove);
  };

  // Add event listeners.
  cardElement.addEventListener('pointermove', onPointerMove, { passive: true });

  // Start the tickers.
  ticker.on('read', readTick, tickerListenerId);
  ticker.on('write', writeTick, tickerListenerId);

  // Return destroy function.
  return destroy;
}

export function useCard(
  cardElementRef: RefObject<HTMLElement | null>,
  options: {
    onActivate?: () => void;
    onDeactivate?: () => void;
    pointerActivation?: {
      frameThreshold?: number;
      timeThreshold?: number;
      movementThreshold?: number;
    } | null;
  } = {},
) {
  const { onActivate, onDeactivate, pointerActivation } = options;
  const cardId = useId();
  const isActive = useStore((state) => state.activeCardId === cardId);
  const setActiveCardId = useStore((state) => state.setActiveCardId);

  // Keep some props and state in ref so we can use them in useEffect without
  // passing them as dependencies.
  const stateRef = useRef<{
    isActive: typeof isActive;
    onActivate: typeof onActivate;
    onDeactivate: typeof onDeactivate;
    pointerActivation: typeof pointerActivation;
    isUnmounted: boolean;
    stopPointerActivationTracker: (() => void) | null;
  }>({
    // Props.
    isActive,
    onActivate,
    onDeactivate,
    pointerActivation,
    // State.
    isUnmounted: false,
    stopPointerActivationTracker: null,
  });
  stateRef.current.isActive = isActive;
  stateRef.current.onActivate = onActivate;
  stateRef.current.onDeactivate = onDeactivate;
  stateRef.current.pointerActivation = pointerActivation;

  const stopPointerActivationTracker = useCallback(() => {
    if (stateRef.current.stopPointerActivationTracker) {
      stateRef.current.stopPointerActivationTracker();
      stateRef.current.stopPointerActivationTracker = null;
    }
  }, []);

  const activateCard = useCallback(() => {
    clearCardDeactivationTimeout();
    if (!isActive) setActiveCardId(cardId);
  }, [cardId, isActive, setActiveCardId]);

  const deactivateCard = useCallback(() => {
    // Let's always stop the pointer activation tracker when deactivating the
    // card, before the deactivation timeout.
    stopPointerActivationTracker();

    // When moving focus/pointer to another card this event might be fired
    // after the new card is activated, in which case we don't want to
    // deactivate the card here anymore as it would undo the new card
    // activation. Additionally, this delay keeps the current card active when
    // changing from keyboard to pointer navigation. The blur event is fired
    // before the pointer event, which will result in the card being deactivated
    // for a very brief moment, unless we have this delay.
    setCardDeactivationTimeout(() => {
      setActiveCardId(null);
    });
  }, [setActiveCardId, stopPointerActivationTracker]);

  const onPointerActivate = useCallback<PointerEventHandler>(
    (event) => {
      if (navSystem.getNavDevice() !== 'keyboard') {
        const { pointerActivation } = stateRef.current;
        if (isActive || pointerActivation === null) {
          activateCard();
        } else if (!stateRef.current.stopPointerActivationTracker) {
          const cardElement = cardElementRef.current;
          if (cardElement) {
            stateRef.current.stopPointerActivationTracker = pointerActivationTracker(
              cardElement,
              { x: event.clientX, y: event.clientY },
              activateCard,
              pointerActivation,
            );
          }
        }
      }
    },
    [isActive, activateCard],
  );

  const onPointerDeactivate = useCallback(() => {
    if (navSystem.getNavDevice() !== 'keyboard') {
      deactivateCard();
    }
  }, [deactivateCard]);

  const onFocusActivate = useCallback(() => {
    activateCard();
  }, [activateCard]);

  const onBlurDeactivate = useCallback(() => {
    deactivateCard();
  }, [deactivateCard]);

  // Deactivate card instantly when it unmounts IF it is active. This is needed
  // because pointerleave event doesn't fire when a hovered element is removed.
  useEffect(() => {
    if (typeof window === 'undefined') return;

    return () => {
      const { isActive, isUnmounted, onDeactivate } = stateRef.current;
      if (!isUnmounted && isActive) {
        stateRef.current.isUnmounted = true;
        clearCardDeactivationTimeout();
        setActiveCardId(null);
        onDeactivate?.();
      }
    };
  }, [setActiveCardId]);

  // Call onActivate and onDeactivate when isActive changes.
  useEffect(() => {
    if (typeof window === 'undefined') return;

    // We want to always stop the pointer activation tracker when isActive
    // changes.
    stopPointerActivationTracker();

    // Get props.
    const { isUnmounted, onActivate, onDeactivate } = stateRef.current;

    if (isUnmounted) return;

    if (isActive) {
      onActivate?.();
    } else {
      onDeactivate?.();
    }
  }, [isActive, stopPointerActivationTracker]);

  return {
    cardId,
    isActive,
    setActiveCardId,
    activateCard,
    deactivateCard,
    onPointerActivate,
    onPointerDeactivate,
    onFocusActivate,
    onBlurDeactivate,
  };
}
